import type { loader as NotificationLoader } from '#app/routes/notification';

import useGlobalKeyListener from '#app/hooks/use-global-key-listener';
import { GlobalAction } from '#app/utils/global-action';
import { cn } from '#app/utils/misc';
import { useOptionalUser,  } from '#app/utils/user';
import { useFetcher, useNavigate } from '@remix-run/react';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';

import type { action as rootAction } from '../../root';

import LogoImage from '../../../other/png/logo.png';
import { Button } from '../ui/button/button';
import { Icon } from '../ui/icon';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover/popover';
import { SearchField } from '../ui/search-field/search-field';
import LibrarySize from './library-size';
import { ProfileEditDialog } from './profile-edit-dialog';
import { SearchDialog } from './search-dialog';
import { SettingDialog } from './setting-dialog';
import SignupDialog from './signup-dialog';
import { VerifyCompanyDialog } from './verify-company-dialog';

// eslint-disable-next-line complexity
const Header = () => {
  const user = useOptionalUser();
  const notifications = useFetcher<typeof NotificationLoader>();
  const isUser = user?.isLoggedIn === true;
  const end = user?.librarySize || 0;
  const ref = React.useRef<HTMLFormElement | null>(null);
  const fetcher = useFetcher<typeof rootAction>();

  const navigate = useNavigate();

  const onSubmit = () => {
    fetcher.submit(ref.current, { action: '/notification', method: 'POST', preventScrollReset: true });
  };

  const handleCmdK = (event: KeyboardEvent) => {
    event.preventDefault();
    openDialog('search');
  };

  useGlobalKeyListener(handleCmdK);


  React.useEffect(() => {
    if (isUser) {
      void notifications.load('/notification');
    }
  }, []);

  const [dialogState, setDialogState] = React.useState<Record<'notification' | 'profileEdit' | 'search' | 'setting' | 'verificationCompany', boolean>>({
    notification       : false,
    profileEdit        : false,
    search             : false,
    setting            : false,
    verificationCompany: false,
  });

  const openDialog = (dialog: 'notification' | 'profileEdit' | 'search' | 'setting' | 'verificationCompany') => {
    setDialogState(prevState => ({ ...prevState, [dialog]: !prevState[dialog] }));
  };

  return (
    <header className="justify-between grid grid-flow-col gap-5 m-5 items-center">
      <section className="grid grid-flow-col gap-6">
        <div className='grid grid-flow-col items-center gap-2 hover:cursor-pointer' data-umami-event="click_logo" onClick={() => navigate('/open-library')}>
          {<img alt='' className='h-8 w-8' src={LogoImage} />}
          <Icon className='h-6 m-1 w-32' name='bookcovery' />
        </div>
        { 
          !isMobileOnly  && 
          <div className='text-point-blue-50 px-4 py-3 bg-point-blue-5 grid gap-1 items-baseline grid-flow-col rounded-full'>
            <span className='text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>🥳</span>
            <LibrarySize end={end} />
          </div>
        }
      </section>
      <SearchField
        command
        inputProps={{
          className  : 'lg:w-[32rem]',
          placeholder: '책 혹은 키워드를 검색해 보세요.',
        }}
        onClick={() => {
          openDialog('search');
        }}
      />
      {
        !isMobileOnly && <article className="grid grid-flow-col gap-5">
          {isUser && 
        <>
          <Popover 
            onOpenChange={() => {
              openDialog('notification');
            }} 
            open={dialogState.notification}
          >
            <PopoverTrigger>
              <fetcher.Form
                className={
                  cn(
                    'grid w-9 h-9 justify-center content-center cursor-pointer rounded-full px-2.5 py-1.5',
                    user.notificationCount === 0 ? 'hover:bg-gray-20 text-gray-90' : 'text-white bg-pink-70 hover:bg-pink-70/90'
                  )
                }
                method='POST'
                ref={ref}
              >
                {user.notificationCount === 0
                  ? <Icon name='regular-notification' size='xlarge' />
                  : <div className='text-body-2-bold xs:font-sfProBold sm:font-pretendardBold'>{user.notificationCount}</div>
                }
                <input name={GlobalAction.ReadNotification} type='hidden' value={'true'} />
              </fetcher.Form>
            </PopoverTrigger>
            <PopoverContent align='end' alignOffset={-20} className='mt-1 w-[24rem] h-[30rem] overflow-auto p-0 rounded-[1.5rem] shadow-[0px_0px_6px_0px_rgba(0,0,0,0.16)]' onCloseAutoFocus={(e) => {
              onSubmit();
            }}>
              <article className='grid grid-flow-row'>
                <div className='p-5'>
                  <h1 className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
                    알림
                  </h1>
                </div>
                <div className='grid grid-flow-row gap-2'>
                  {
                    notifications.data?.notifications.map((notification) => {
                      const [my, review] = notification.url.split('|');
                      return (
                        <div className='grid grid-flow-row gap-2' key={notification.id}>
                          <div className='grid grid-flow-col gap-4 hover:cursor-pointer px-5'>
                            <div 
                              onClick={() => {
                                openDialog('notification');
                                navigate(my);
                              }}
                            >
                              <img
                                alt='userProfileImage'
                                className='bg-red-50 w-10 h-10 object-cover rounded-md object-center border border-gray-5'
                                src={notification.thumbnail}
                              />
                            </div>
                            <div onClick={() => {
                              openDialog('notification');
                              navigate(review);
                            }}>
                              <div className='text-body-1-regular xs:font-sfProLight sm:font-pretendardLight' dangerouslySetInnerHTML={
                                {__html: notification.description}
                              }/>
                            </div>
                            {notification.read === false && 
                              <div className='grid items-center'>
                                <div className={cn('w-2 h-2 border-solid border-1 border-white rounded-full bg-pink-70')} />
                              </div>
                            }
                          </div>
                          {/* <div className='grid justify-start px-5'>
                            <Button rounded={'md'} size='small' variant={'primary'}>
                              버튼버튼
                            </Button>
                          </div> */}
                        </div>
                      );
                    })
                  }
                </div>
              </article>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger asChild>
              <div className='min-w-10 min-h-10 max-h-10 max-w-10 rounded-sm cursor-pointer overflow-hidden relative'>
                <img
                  alt='userProfileImage'
                  className='rounded-lg absolute object-cover h-full w-full top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]'
                  src={user.imageUrl ?? ''}
                />
              </div>
            </PopoverTrigger>
            <PopoverContent>
              <article className='p-2.5 text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-90 cursor-pointer active:bg-gray-20 hover:bg-gray-20 rounded-md' 
                onClick={() => {
                  openDialog('profileEdit');
                }}>
                프로필 수정
              </article>
              <article className='p-2.5 text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-90 cursor-pointer active:bg-gray-20 hover:bg-gray-20 rounded-md'
                onClick={() => {
                  openDialog('verificationCompany');
                }}>
                회사정보 인증
              </article>
              {/* <article className='p-2.5 text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-90 cursor-pointer active:bg-gray-20 hover:bg-gray-20 rounded-md'>
                최근 활동
              </article> */}
              <article className='p-2.5 text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-90 cursor-pointer active:bg-gray-20 hover:bg-gray-20 rounded-md'
                onClick={() => {
                  openDialog('setting');
                }}>
                설정
              </article>
            </PopoverContent>
          </Popover>
        </>
          }
          {
            !isUser && (
              <SignupDialog />
            )
          }
          {
            dialogState.profileEdit 
              && <ProfileEditDialog open={dialogState.profileEdit} setOpen={() => {
                openDialog('profileEdit');
              }} />
          }
          {
            dialogState.verificationCompany && <VerifyCompanyDialog open={dialogState.verificationCompany} setOpen={() => {
              openDialog('verificationCompany');
            }}  />
          }
          {
            dialogState.setting && <SettingDialog open={dialogState.setting} setOpen={() => {
              openDialog('setting');
            }}  />
          }
          {
            dialogState.search && <SearchDialog open={dialogState.search} setOpen={() => {
              openDialog('search');
            } } />
          }
        </article>
      }
    </header>
  );
};


export default Header;
