/* eslint-disable perfectionist/sort-objects */


import { Icon } from '#app/components/ui/icon';

export enum NavigationEnum {
  /**
   * 키워드
   */
  Keyword = 'keyword',
  /**
   * 내 서재
   */
  MyLibrary = 'myLibrary',
  /**
   * 오픈 서재
   */
  OpenLibrary = 'openLibrary',
  // UI = 'UI',
  /**
   * 읽고 싶어요
   */
  WantRead = 'wantRead',
}

interface NavigationValue {
  activeKey: string;
  icon: (isActive: boolean) => React.ReactNode;
  key: keyof typeof NavigationEnum;
  loggedIn: boolean;
  name: string;
  url: Url[NavigationEnum];
}

interface Url {
  [NavigationEnum.Keyword]: string;
  [NavigationEnum.MyLibrary]: (userId: number) => string;
  [NavigationEnum.OpenLibrary]: string;
  // [NavigationEnum.UI]: string;
  [NavigationEnum.WantRead]: string;
}

export const PageInfo: Record<NavigationEnum, NavigationValue> =  {
  [NavigationEnum.OpenLibrary]: {
    icon     : (isActive) => <Icon name={`${isActive ? 'fill' : 'regular'}-shelf`} size={'large'} />,
    key      : 'OpenLibrary',
    name     : '오픈서재',
    url      : '/open-library',
    loggedIn : false,
    activeKey: 'routes/_open-library+/open-library.$keyword'
  },
  [NavigationEnum.Keyword]: {
    icon     : (isActive) => <Icon name={`${isActive ? 'fill' : 'regular'}-hashtag`} size={'large'} />,
    key      : 'Keyword',
    name     : '키워드 서재',
    url      : '/keyword',
    loggedIn : false,
    activeKey: 'routes/keyword'
  },
  [NavigationEnum.MyLibrary]: {
    icon     : (isActive) => <Icon name={`${isActive ? 'fill' : 'regular'}-door`} size={'large'} />,
    key      : 'MyLibrary',
    name     : '내 서재',
    url      : (userId) => `/my-library/${userId}/library`,
    loggedIn : true,
    activeKey: 'routes/_my-library+/my-library.$userId'
  },
  // [NavigationEnum.UI]: {
  //   icon     : (isActive) => <Icon name={`${isActive ? 'fill' : 'thin'}-bookmark`} size={'large'} />,
  //   key      : 'UI',
  //   name     : 'UI 구경가기',
  //   url      : '/ui',
  //   loggedIn : true,
  //   activeKey: 'routes/ui'
  // },
  [NavigationEnum.WantRead]: {
    icon     : (isActive) => <Icon name={`${isActive ? 'fill' : 'regular'}-bookmark`} size={'large'} />,
    key      : 'WantRead',
    name     : '읽고 싶은 책',
    url      : '/want-read',
    loggedIn : true,
    activeKey: 'routes/want-read'
  }
};