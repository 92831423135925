import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import React from 'react';

const useParticles = () => {
  React.useEffect(() => {
    void initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    });
  }, []);

  const component = React.useMemo(() => <Particles
    className='z-[1000] fixed'
    id="tsparticles"
    options={{
      duration    : 3,
      'fullScreen': {
        'zIndex': 1000000
      },
      'particles': {
        'color': {
          'value': [
            '#FFFFFF',
            '#FFd700',
            {b: 253, g: 122, r: 100}
          ]
        },
        'move': {
          'direction': 'bottom',
          'enable'   : true,
          'outModes' : {
            'default': 'out'
          },
          'size' : true,
          'speed': {
            'max': 3,
            'min': 1
          }
        },
        'number': {
          'density': {
            'enable': true
          },
          'value': 500
        },
        'opacity': {
          'animation': {
            'destroy'   : 'min',
            'enable'    : false,
            'speed'     : 0.3,
            'startValue': 'max',
            'sync'      : true,
          },
          'value': 1
        },
        'roll': {
          'darken': {
            'enable': true,
            'value' : 30
          },
          'enable'   : true,
          'enlighten': {
            'enable': true,
            'value' : 30
          },
          'speed': {
            'max': 25,
            'min': 15
          }
        },
        'rotate': {
          'animation': {
            'enable': true,
            'speed' : 60
          },
          'direction': 'random',
          'move'     : true,
          'value'    : {
            'max': 360,
            'min': 0
          }
        },
        'shape': {
          'options': {},
          'type'   : [
            'circle',
            'square'
          ]
        },
        'size': {
          'value': {
            'max': 4,
            'min': 2
          }
        },
        'tilt': {
          'animation': {
            'enable': true,
            'speed' : 60
          },
          'direction': 'random',
          'enable'   : true,
          'move'     : true,
          'value'    : {
            'max': 360,
            'min': 0
          }
        },
        'wobble': {
          'distance': 30,
          'enable'  : true,
          'move'    : true,
          'speed'   : {
            'max': 15,
            'min': -15
          }
        },
      },
    }}
  />, []);

  return component;
};

export default useParticles;
