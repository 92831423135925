import { cn } from '#app/utils/misc';
import { cva } from 'class-variance-authority';
import React from 'react';
import { match } from 'ts-pattern';

import { Icon } from '../icon';

type CollectionType = 'keywords' | 'subtitle';

type Props = {
  children: React.ReactNode;
  collectionType: CollectionType;
  groupName: string;
};
export const KeywordList: React.FC<Props> = ({ children, collectionType, groupName }) => {

  const cv = cva('grid grid-flow-col gap-1 justify-start', {
    variants: {
      collectionType: {
        keywords: 'text-label-2-bold font-pretendardBold text-gray-90',
        subtitle: 'text-label-2-medium font-pretendardMedium text-gray-60',
      }
    }
  });

  return (
    <article className='grid grid-flow-row gap-3'>
      <div>
        <span className={cn(cv({ collectionType }))}>
          {
            match(collectionType)
              .with('keywords', () => (
                groupName !== '커리어'
                  ? <Icon name='regular-hashtag' size='medium' />
                  : <Icon name='regular-condo' size='medium' />
              ))
              .with('subtitle', () => null)
              .otherwise(() => null)
          }
          {groupName}
        </span>
      </div>
      <div className='flex flex-wrap gap-2'>
        {children}
      </div>
    </article>
  );
};