import { type Toast } from '#app/utils/toast.server.ts';
import { useEffect } from 'react';
import { toast as showToast } from 'sonner';

export function useToast(toast?: Toast | null) {
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        showToast[toast.type](toast.title, {
          description: toast.description,
          id         : toast.id,
          unstyled   : true,
        });
      }, 0);
    }
  }, [toast]);
}
