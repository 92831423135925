import type { Keyword } from '@prisma/client';

import { updateMarketingSchema } from '#app/action/update-marketing';
import { GlobalAction } from '#app/utils/global-action';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { Form, useFetcher, useRouteLoaderData } from '@remix-run/react';
import React, { useRef } from 'react';

import type { action as rootAction, loader as rootLoader } from '../../root';

import { Field } from '../forms';
import { TextButton } from '../ui/button/text-button';
import { Icon } from '../ui/icon';
import { Modal, ModalContent, ModalHeader, ModalMain, ModalTitle } from '../ui/modal/modal';
import { Switch } from '../ui/switch/switch';
import { FollowKeywordsDialog } from './follow-keywords-dialog';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}
export const SettingDialog: React.FC<Props> = ({ open, setOpen }) => {
  const fetcher = useFetcher<typeof rootAction>();
  const ref = useRef<HTMLFormElement | null>(null);
  const rootData = useRouteLoaderData<typeof rootLoader>('root');
  const [form, fields] = useForm({
    constraint: getZodConstraint(updateMarketingSchema),
    id        : GlobalAction.Setting,
    onValidate: ({ formData }) => {
      return parseWithZod(formData, {schema: updateMarketingSchema});
    },
  });
  const [checked, setChecked] = React.useState<boolean>(rootData?.user?.agree?.marketing ?? false);
  
  return (
    <Modal 
      onOpenChange={setOpen}
      open={open}
    >
      <ModalContent className='w-[50rem] h-[37.5rem] grid grid-rows-[auto_1fr]' showClose={true} size='large'>
        <ModalHeader className='mt-6'>
          <ModalTitle className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
              설정
          </ModalTitle>
        </ModalHeader>    
        <ModalMain className='grid grid-flow-row px-10 py-5 gap-10 overflow-auto'>
          <article className='grid grid-flow-row gap-5'>
            <h1 className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-90'>계정 정보</h1>
            <div className='grid grid-flow-row gap-[1.875rem]'>
              <section className='grid grid-flow-row gap-2.5'>
                <h2 className='text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>
                    이메일
                </h2>
                <div className='grid grid-flow-col items-center justify-between'>
                  <p className='text-label-1-regular xs:font-sfProLight sm:font-pretendardLight text-gray-90'>
                    {rootData?.user?.email}
                  </p>
                </div>
              </section>
              <section className='grid grid-flow-row gap-2.5'>
                <h2 className='text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>
                  관심 키워드
                </h2>
                <div className='grid grid-flow-col items-center justify-between'>
                  <FollowKeywordsDialog
                    careerKeyword={rootData?.user?.careerKeyword as unknown as Keyword}
                    groupedKeyword={rootData?.groupedKeyword as unknown as Record<string, Keyword[]>}
                    onNext={() => { }}
                    onPrev={() => { }}
                    selectedKeywords={rootData?.user?.followKeywords?.map(k => k.keyword) as unknown as Keyword[]}
                  />
                </div>
              </section>
              <section className='grid grid-flow-row gap-2.5'>
                <div className='grid grid-flow-col items-center justify-between'>
                  <Form action='/logout' method='POST'>
                    <TextButton size='medium' variant={'gray'}>
                      로그아웃  
                    </TextButton>
                  </Form>
                </div>
              </section>
            </div>
          </article>
          <div className='border-b border-b-gray-20' />
          <article className='grid grid-flow-row gap-5'>
            <h1 className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-90'>알림 설정</h1>
            <div className='grid grid-flow-col justify-between'>
              <section className='grid grid-flow-row gap-0.5'>
                <h2 className='text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>
                    마케팅 수신동의
                </h2>
                <span className='text-gray-50 text-caption-2-regular xs:font-sfProLight sm:font-pretendardLight'>
                    이메일로 마케팅 메세지를 수신합니다.
                </span>
              </section>
              <fetcher.Form id={form.id} method='POST' ref={ref}>
                <div className='grid grid-flow-col gap-2 items-center'>
                  {checked && <span className='text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-point-blue-60'>수신</span>}
                  {!checked && <span className='text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60'>미수신</span>}
                  <Switch
                    checked={checked}
                    onCheckedChange={(v) => {
                      setChecked(v);
                      void fetcher.submit(ref.current, { method: 'POST' });
                    } }/>

                </div>
                <div className='h-0 hidden'>
                  <Field
                    inputProps={{
                      value: (!checked).toString(),
                      ...getInputProps(fields[GlobalAction.Setting], {type: 'hidden'}),
                    }}
                  />
                </div>
              </fetcher.Form>
            </div>
          </article>
          <div className='border-b border-b-gray-20' />
          <article className='grid grid-flow-row gap-5'>
            <h1 className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-90'>About</h1>
            <div className='grid grid-flow-row gap-2.5 text-gray-90 text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium'>
              <span className='cursor-pointer grid grid-flow-col justify-start gap-2' onClick={() => {
                window.open('https://www.notion.so/c7e125532a6b409b9db0179e4c543704', '_blank');
              }}>서비스 소개 <Icon className='text-gray-40' name='regular-arrow' size='large' /></span>
              <span className='cursor-pointer grid grid-flow-col justify-start gap-2' onClick={() => {
                window.open('https://www.notion.so/92c58cef635a4fd08663942a6d4e7288', '_blank');
              }}>개인정보 수집 및 이용약관 <Icon className='text-gray-40' name='regular-arrow' size='large' /></span>
              <span className='cursor-pointer grid grid-flow-col justify-start gap-2' onClick={() => {
                window.open('https://www.notion.so/7f574a4a03a540ea846dd638aa712b11', '_blank');
              }}>개인정보보호 정책 <Icon className='text-gray-40' name='regular-arrow' size='large' /></span>
              <span className='cursor-pointer grid grid-flow-col justify-start gap-2' onClick={() => {
                window.open(`https://forms.fillout.com/t/2bWgfRdAFuus?email=${rootData?.user?.email ?? ''}`, '_blank');
              }}>제휴 및 광고 문의 <Icon className='text-gray-40' name='regular-arrow' size='large' /></span>
              <span className='cursor-pointer grid grid-flow-col justify-start gap-2' onClick={() => {
                window.open(`https://forms.fillout.com/t/krWPS12rR4us?email=${rootData?.user?.email ?? ''}`, '_blank');
              }}>서비스 피드백 및 버그 제보 <Icon className='text-gray-40' name='regular-arrow' size='large' /></span>
            </div>
          </article>
          <div className='border-b border-b-gray-20' />
          <article className='grid grid-flow-row gap-5'>
            <h1 className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-90'>계정 </h1>
            <div className='grid grid-flow-row gap-2.5 text-gray-90 text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium'>
              <section className='grid grid-flow-row gap-2.5'>
                <div className='grid grid-flow-col items-center justify-between'>
                  <TextButton data-umami-event="click_delete_account" size='medium' variant={'gray'}>
                    계정 삭제
                  </TextButton>
                </div>
              </section>
            </div>
          </article>
        </ModalMain>
      </ModalContent>
    </Modal>
  );
};