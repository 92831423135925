import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ theme, ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group fixed bottom-10 z-[1000] right-[50%] transform translate-x-1/2 max-w-2xl"
      expand={false}
      toastOptions={{
        classNames: {
          description: 'group-[.toast]:text-muted-foreground group-[.toast]:text-white group-[.toast]:text-label-2-regular xs:font-sfProLight sm:xs:font-sfProLight sm:font-pretendardLight',
          toast:
          'group toast rounded-lg grid grid-flow-col px-6 items-center justify-center py-3 group-[.toaster]:bg-alpha-4 group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
        },
        duration: 3000,
      }}
      {...props}
    />
  );
};

export { Toaster };
