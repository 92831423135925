import React from 'react';
import * as CountUp from 'use-count-up';
interface Props {
  end: number;
}
const LibrarySize: React.FC<Props> = ({ end }) => {
  // const { value } = CountUp.useCountUp({
  //   duration          : 3.2,
  //   end               : end,
  //   isCounting        : true,
  //   start             : Math.floor(end / 1.7),
  //   thousandsSeparator: ',',
  // });
  return (
    <span className='text-caption-1-bold xs:font-sfProBold sm:font-pretendardBold' data-umami-event="click_now_bookcovery">북커버리는 지금 {end.toLocaleString()}평</span>
  );
};

export default LibrarySize;
