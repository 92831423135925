import { useCallback, useEffect, useRef } from 'react';

type KeyCombinationHandler = (event: KeyboardEvent) => void;

const useGlobalKeyListener = (keyCombinationHandler: KeyCombinationHandler) => {
  const f = useRef(false);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Meta') {
      f.current = true;
    }
    if (f.current && event.key === 'k') {
      f.current = false;
      keyCombinationHandler(event);
    }
  }, [keyCombinationHandler]);

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Meta') {
      f.current = false;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  return f.current;
};

export default useGlobalKeyListener;