import type {loader as rootLoader} from '#app/root';
import type { useRouteLoaderData} from '@remix-run/react';

import { Button } from '#app/components/ui/button/button';
import { Modal, ModalContent, ModalMain } from '#app/components/ui/modal/modal';
import { NavigationEnum, PageInfo } from '#app/hooks/page-info';
import { useNavigate } from '@remix-run/react';
import React, {  } from 'react';

interface Props {
  readonly rootData: ReturnType<typeof useRouteLoaderData<typeof rootLoader>>
}
export const CongratuFirstUploadDialog: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  
  return (
    <Modal onOpenChange={() => setOpen(state => !state)} open={open}>
      <ModalContent
        showClose={true}
        size='medium'
      >
        <ModalMain className={'px-[3.75rem] py-10 grid gap-10'}>
          <div className='grid gap-4 justify-center text-center'>
            <span className='text-heading-3-bold xs:font-sfProBold sm:font-pretendardBold'>🎉</span>
            <h1 className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>첫 업로드 완료</h1>
            <h2 className='text-body-1-paragraph-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60'>처음으로 북커버리 오픈서재에 책을 공유하셨네요!. <br/> 내 책장에서 확인해보세요!</h2>
          </div>
          <div>
            <Button
              children='내 책장 보러가기'
              data-umami-event='click_go_my_library'
              onClick={() => {
                if (typeof PageInfo[NavigationEnum.MyLibrary].url === 'function') {
                  navigate(PageInfo[NavigationEnum.MyLibrary].url(props.rootData!.user!.id));
                  setOpen(false);
                }
              }}
              rounded={'md'}
              size='medium'
              variant='primary'
            />
          </div>
        </ModalMain>
      </ModalContent> 
    </Modal>
  );
};