import { prisma } from '#app/utils/db.server';
import { GlobalAction } from '#app/utils/global-action';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const updateMarketingSchema = z.object({
  [GlobalAction.Setting]: z.string(),
});
/**
 * @description 마케팅수신동의변경
 * @param userId 가입한 유저 아이디
 * @param formData 폼 데이터
 */
export const updateMarketing = async (userId: number, formData: FormData) => {
  logger.info('updateMarketing called!');
  const response = await parseWithZod(formData, {
    async : true,
    schema: updateMarketingSchema.superRefine(async (data) => {
      const agree = await prisma.agree.findFirstOrThrow({
        where: {
          marketing: data[GlobalAction.Setting] === 'true' ? true : false,
          required : true,
        }
      });
      await prisma.$transaction([
        prisma.user.update({
          data: {
            agreeId: agree.id,
          },
          where: { id: userId },
        }),
        prisma.actionHistory.create({
          data: {
            action: 'update-marketing',
            extra : {
              asIs: data[GlobalAction.Setting] === 'true' ? false : true,
              toBe: data[GlobalAction.Setting] === 'true' ? true : false,
            },
            userId: userId
          }
        })
      ]);
    }),
  },);
  logger.info('updateMarketing done!');
  return response.status;
};