import { Button } from '#app/components/ui/button/button';
import { ProviderButton, providerNames } from '#app/utils/connections';
import { GlobalDialog } from '#app/utils/global-dialog.ts';
import { useSearchParams } from '@remix-run/react';
import React from 'react';
import { isMobile } from 'react-device-detect';

import Signup from '../../../other/png/signup.png';
import { TextButton } from '../ui/button/text-button';
import { Modal, ModalCardImage, ModalContent, ModalMain, ModalTrigger } from '../ui/modal/modal';

const SignupDialog = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open] = React.useState(searchParams.has(GlobalDialog.Signup));

  return (
    <Modal 
      onOpenChange={(value) => {
        const params = new URLSearchParams();
        if (value) {
          params.set(GlobalDialog.Signup, '');
          void setSearchParams(params);
        } else {
          params.delete(GlobalDialog.Signup);  
          void setSearchParams(params);
        }
      }}
      open={open}
    >
      <ModalTrigger asChild>
        <Button rounded='md' size='medium' variant='primary'>
          북커버리 시작하기
        </Button>
      </ModalTrigger>
      <ModalContent
        showClose={true}
        size={isMobile ? 'full' : 'medium'}
      >
        <ModalCardImage alt='' className='rounded-md' src={Signup} />
        <ModalMain className='px-[3.75rem] py-[3.75rem]'>
          <section className='grid grid-flow-row gap-10 justify-center'>
            <div className='grid grid-flow-row gap-5'>
              <h1 className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
                  반가워요!
                <br/>
                  북커버리 시작하기
              </h1>
              <h2 className='text-body-2-paragraph-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60'>
                요즘 일하는 사람들이 책을 찾는 새로운 방법.
                <br/>
                뛰어난 커리어를 가진 사람들과 도서를 나누고, 나에게 꼭 필요한 책을 찾으세요.
              </h2>
            </div>
            <div className='grid grid-flow-row gap-3'>
              <div className='grid grid-flow-row gap-2'>
                {providerNames.map(providerName => <ProviderButton key={providerName} providerName={providerName} />).reverse()}
              </div>
              <div className='text-gray-50 text-caption-2-regular xs:font-sfProLight sm:font-pretendardLight'>
                로그인은&nbsp;
                <TextButton className='text-gray-50 decoration-gray-50 text-caption-2-regular xs:font-sfProLight sm:font-pretendardLight' onClick={() => {
                  window.open('https://www.notion.so/7f574a4a03a540ea846dd638aa712b11', '_blank');
                }} size='xsmall' underline={'underline'}>개인정보보호 정책</TextButton>
                &nbsp;및&nbsp;
                <TextButton  className='text-gray-50 decoration-gray-50 text-caption-2-regular xs:font-sfProLight sm:font-pretendardLight'onClick={() => {
                  window.open('https://www.notion.so/92c58cef635a4fd08663942a6d4e7288', '_blank');
                }} size='xsmall' underline={'underline'}>서비스 약관</TextButton>에 동의하는 것을 의미합니다.
              </div>
            </div>
          </section>
        </ModalMain>
      </ModalContent>
    </Modal>
  );
};

export default SignupDialog;
