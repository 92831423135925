import type { loader as searchCareerLoader } from '#app/routes/keyword-loader+/search-career';
import type { loader as searchKeywordLoader } from '#app/routes/keyword-loader+/search-keyword';
import type { loader as SearchAllLoader } from '#app/routes/search+/all';
import type { loader as SearchISBNLoader } from '#app/routes/search+/isbn';

import { GlobalAction } from '#app/utils/global-action';
import { cn, useDebounce } from '#app/utils/misc';
import { useOptionalUser } from '#app/utils/user';
import { getInputProps, useForm } from '@conform-to/react';
import { useFetcher, useNavigate, useSearchParams } from '@remix-run/react';
import React from 'react';
import { P, match } from 'ts-pattern';

import type { IconName } from '../ui/icon';

import Chip from '../ui/chip/chip';
import { Icon } from '../ui/icon';
import { KeywordList } from '../ui/keyword-list/keyword-list';
import { Modal, ModalContent, ModalMain } from '../ui/modal/modal';
import { SearchBook } from '../ui/search-book/search-book';
import { SearchField } from '../ui/search-field/search-field';
import { LoadingChip, LoadingSearchBook } from './upload-book-dialog';

type GroupItemName = '커리어' | '키워드';
type Group = {
  name: GroupItemName;
  prefixIcon: IconName
};
interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}
export const SearchDialog: React.FC<Props> = ({open, setOpen}) => {
  const searchAll = useFetcher<typeof SearchAllLoader>();
  const searchISBNLoader = useFetcher<typeof SearchISBNLoader>();
  const user = useOptionalUser();
  const navigate = useNavigate();
  const ref = React.useRef<HTMLDivElement>(null);
  const [, setSearchParams] = useSearchParams();
  const actionRef = React.useRef<HTMLFormElement>(null);
  const prevSearchText = React.useRef<string | undefined>(undefined);
  const [form, fields] = useForm({
    defaultValue: {
      [GlobalAction.SearchAll]: undefined,
    },
    id: 'search-all-form',
  });
  const handleFormChange = useDebounce((form: HTMLFormElement) => {
    void searchAll.submit(form, {
      action: '/search/all',
      method: 'GET',
    });
    prevSearchText.current = fields[GlobalAction.SearchAll].value;
  }, 300);

  const onClick = (name: string) => {
    void navigate(`/keyword-library/${name.replaceAll('/', '-')}`);
    void setTimeout(() => setOpen(false), 0);
  };

  
  React.useEffect(() => {
    if(searchISBNLoader.data) {
      void navigate(`/books/${searchISBNLoader.data.id}`);
      void setTimeout(() => setOpen(false), 0);
    }
  }, [searchISBNLoader.data]);


  return (
    <Modal
      onOpenChange={setOpen}
      open={open}
    >
      <ModalContent className='w-[50rem] h-[42.5rem] grid grid-rows-[auto_1fr] rounded-[1.75rem]' showClose={false} size='large'>
        <section className='mx-5 my-4'>
          <searchAll.Form
            id={form.id} 
            onChange={e => handleFormChange(e.currentTarget)}
            onKeyDown={
              (e) => {
                if(e.key === 'Enter') {
                  e.preventDefault();
                }
                ref.current?.scrollTo({behavior: 'instant', top: 0});
              }
            }
          >
            <SearchField
              inputProps={{
                placeholder: '책 혹은 키워드를 검색해 보세요.',
                ...getInputProps(fields[GlobalAction.SearchAll], {type: 'text'})
              }}
            />
          </searchAll.Form>
        </section>
        <ModalMain className='px-5 py-3 overflow-auto' ref={ref}>
          {
            !fields[GlobalAction.SearchAll].value
              ? <Ready onClose={() => setOpen(false)} />
              : match(searchAll)
                .with({
                  data : P.nullish,
                  state: 'idle',
                }, () => <Ready onClose={() => setOpen(false)} />)
                .with({
                  state: 'loading'
                }, () => {
                  return <div className='grid grid-flow-row gap-5'>
                    <div className='grid grid-flow-col justify-start gap-2'>
                      {Array.from({length: 7}).map((_, index) => <LoadingChip key={index}  />)}
                    </div>
                    <div className='grid gap-1'>
                      {Array.from({length: 7}).map((_, index) => <LoadingSearchBook key={index}  />)}
                    </div>
                  </div>;
                })
                .with({
                  data : P.select(),
                  state: 'idle'
                }, (v) => {
                  if(fields[GlobalAction.SearchAll].value !== prevSearchText.current) {
                    return <div className='grid grid-flow-row gap-5'>
                      <div className='grid grid-flow-col justify-start gap-2'>
                        {Array.from({length: 7}).map((_, index) => <LoadingChip key={index}  />)}
                      </div>
                      <div className='grid gap-1'>
                        {Array.from({length: 7}).map((_, index) => <LoadingSearchBook key={index}  />)}
                      </div>
                    </div>;
                  }
                  
                  if(!v) {
                    return <>검색 실패</>;
                  }

                  if(v.books?.length === 0 && v.careerKeywords?.length === 0 && Object.keys(v.groupedKeywords).length === 0) {
                    return <Empty />;
                  }
                  const renders = [];
                  
                  if(Object.keys(v.groupedKeywords).length > 0) {
                    renders.push(
                      <KeywordList
                        collectionType='subtitle'
                        groupName='키워드'
                      >
                        {
                          Object.entries(v.groupedKeywords).map(([key, value]) => {
                            return value.map((keyword, i) => {
                              return <Chip
                                key={keyword.id}
                                name={'chip-key'}
                                onClick={(e) => {
                                  onClick(keyword.name);
                                }} 
                                size='small'
                                value={keyword.name}
                                variant={'gray'}
                              >
                                {keyword.name}
                              </Chip>;
                            });
                          })
                        }
                      </KeywordList>
                    );
                  }
                  if(v.careerKeywords.length > 0) {
                    renders.push(
                      <KeywordList
                        collectionType='subtitle'
                        groupName='커리어'
                      >
                        {
                          v.careerKeywords.map((keyword, i) => {
                            return <Chip
                              key={keyword!.id}
                              name={'chip-key'}
                              onClick={(e) => {
                                onClick(keyword!.name);
                              }} 
                              size='small'
                              value={keyword!.name}
                              variant={'gray'}
                            >
                              {keyword!.name}
                            </Chip>;
                          })
                        }                     
                      </KeywordList>
                    );
                  }
                  if(v.books.length > 0) {
                    renders.push(
                      <div className='grid grid-flow-row gap-1'>
                        <h1 className='text-gray-60 text-label-2-medium font-pretendardMedium pl-2'>책</h1>
                        
                        {v.books.map((book) => (
                          <searchISBNLoader.Form
                            action={`/search/isbn?isbn=${book!.isbn}`}
                            key={book!.isbn}
                            method='GET'
                            ref={actionRef}
                          >
                            <input name="searchBook" type="hidden" value={JSON.stringify(book)} />
                            <SearchBook
                              author={book!.author ?? ''}
                              className='p-2'
                              img={book!.image ?? ''}
                              isUploaded={book!.isUploaded}
                              onClick={() => {
                                if(user.isLoggedIn === false || !user.isLoggedIn) {
                                  const searchParams = new URLSearchParams();
                                  void searchParams.set('signup', '');
                                  void setSearchParams(searchParams);
                                  return;
                                }
                                void searchISBNLoader.load(`/search/isbn?isbn=${book!.isbn}`);
                              }}
                              readCount={book!.readCount}
                              reviewCount={book!.reviewCount} 
                              title={book!.title}
                            />
                          </searchISBNLoader.Form>
                        ))}
                      </div>
                    );
                  }

                  return <div className='grid gap-5'>
                    {renders}
                  </div>;
                })
                .otherwise((value) => null)
          }
        </ModalMain>
      </ModalContent>
    </Modal>
  );
};

const Empty = () => {
  return (
    <div className='grid justify-center text-center gap-3 h-full content-center'>
      <p className='text-subtitle-1-bold-desktop font-pretendardBold text-gray-70'>찾는 결과가 없습니다.</p>
      <p className='text-label-2-regular font-pretendardLight text-gray-60'>검색어에 맞는 키워드가 없습니다.</p>
    </div>
  );
};


interface ReadyProps {
  onClose: () => void;
}
const Ready: React.FC<ReadyProps> = ({
  onClose,
}) => {

  const groupTriggers: Group[] = [
    {
      name      : '키워드',
      prefixIcon: 'regular-hashtag'
    },
    {
      name      : '커리어',
      prefixIcon: 'regular-condo'
    }
  ];

  const [selectedGroupItem, setSelectedGroupItem] = React.useState<GroupItemName>('키워드');
  const navigate = useNavigate();
  const searchKeywordFetcher = useFetcher<typeof searchKeywordLoader>();
  const searchCareerFetcher = useFetcher<typeof searchCareerLoader>();

  React.useEffect(() => {
    if(selectedGroupItem === '키워드') {
      searchKeywordFetcher.load('/keyword-loader/search-keyword');
    } else {
      searchCareerFetcher.load('/keyword-loader/search-career');
    }

    return () => {
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupItem]);

  const onClick = (name: string) => {
    void navigate(`/keyword-library/${name.replaceAll('/', '-')}`);
    void setTimeout(() => onClose(), 0);
  };

  return (
    <div className='grid grid-cols-[200px_1fr] h-full top-0 gap-5'>
      <div className='flex-col'>
        {groupTriggers.map((groupItem, index) => (
          <GroupTrigger
            key={index}
            name={groupItem.name}
            onClick={setSelectedGroupItem}
            prefixIcon={groupItem.prefixIcon}
            selected={selectedGroupItem === groupItem.name}
          />
        ))}
      </div>
      <div className='overflow-y-auto'>
        {
          match(selectedGroupItem)
            .with('키워드', () => (
              <div className='flex gap-5 flex-col'>
                {
                  searchKeywordFetcher.state === 'loading' && Array.from({ length: 5 }).map((_, index) => {
                    return <div className='flex flex-col gap-3' key={index}>
                      <div className='flex flex-row gap-1 items-center'>
                        <article className='h-8 w-10 rounded-lg bg-gray-20'>
                        </article>
                      </div>
                      <div className='flex gap-2 flex-wrap'>
                        {Array.from({ length: Math.floor(Math.random() * 10) + 1 }).map((_, index) => <LoadingChip key={index} />)}
                      </div>
                    </div>;
                  })
                }
                {
                  searchKeywordFetcher.data && Object.entries(searchKeywordFetcher.data.groupedKeywords).map(([key, value]) => (
                    <KeywordList
                      collectionType='keywords'
                      groupName={key}
                      key={key}
                    >
                      {
                        value.map((keyword, i) => {
                          return <Chip
                            key={keyword.id}
                            name={'chip-key'}
                            onClick={(e) => {
                              onClick(keyword.name);
                            }} 
                            size='small'
                            value={keyword.name}
                            variant={'gray'}
                          >
                            {keyword.name}
                          </Chip>;
                        })
                      }
                    </KeywordList>
                  ))
                }
              </div>
            ))
            .with('커리어', () => (
              <div className='flex flex-col'>
                {
                  searchCareerFetcher.data?.keywords?.map(keyword => {
                    return <article
                      className='px-2 py-2.5 hover:cursor-pointer hover:bg-gray-10 rounded-lg grid grid-flow-col justify-between' key={keyword.id} 
                      onClick={() => {
                        onClick(keyword.name);
                      }}
                    >
                      <h1 className='text-label-1-medium text-gray-100 font-pretendardMedium'>
                        {keyword.name}
                      </h1>
                      <span className='text-label-2-regular font-pretendardLight text-gray-60'>
                        {keyword.reviewCount > 0 && (
                          <span>
                                책 {keyword.reviewCount.toLocaleString()}권
                          </span>
                        )}
                        {keyword.reviewCount > 0 && keyword.followCount > 0 && (
                          <span className="mx-1">&middot;</span>
                        )}
                        {keyword.followCount > 0 && (
                          <span>
                                팔로워 {keyword.followCount.toLocaleString()}명
                          </span>
                        )}
                      </span>
                    </article>;
                  })
                }
                    
              </div>
            ))
            .exhaustive()
        }
      </div>
    </div>
  );
};

interface GroupItemProps {
  name: GroupItemName;
  onClick: (selectedItem: GroupItemName) => void;
  prefixIcon: IconName;
  selected: boolean;
}
const GroupTrigger: React.FC<GroupItemProps> = ({
  name,
  onClick,
  prefixIcon,
  selected
}) => {

  const iconName: IconName = selected
    ? `fill-${prefixIcon.split('-')[1]}` as IconName
    : prefixIcon;

  return (
    <div 
      className={
        cn(
          'grid grid-flow-col gap-3 items-center hover:cursor-pointer justify-start p-2 rounded-xl',
          {
            'bg-gray-10': selected,
          }
        )
      }
      onClick={() => onClick(name)}>
      <div className={cn(
        'grid items-center border border-gray-20 rounded-[10px] p-3',
        {
          'bg-white': selected,
        }
      )}>
        <Icon name={iconName} size='large' />
      </div>
      <h1 className='text-label-1-medium font-pretendardMedium max-w-[260px] line-clamp-1 text-gray-90 text-ellipsis overflow-hidden'>
        {name}
      </h1>
    </div>
  );
};