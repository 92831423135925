import type {PropsWithChildren} from 'react';

import { useLocation } from '@remix-run/react';
// eslint-disable-next-line no-duplicate-imports
import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';

import Header from './desktop/header';
import Sidebar from './desktop/sidebar';


interface Props { }
const Responsive: React.FC<PropsWithChildren<Props>> = ({children}) => {

  const isDesktop = useMediaQuery({ minWidth: 571 });
  const isMobile = isIOS || isAndroid;
  const { pathname } = useLocation();

  const hideSidebar = (v: string) => {
    const hideSidebar = [
      '/company/verify-complete',
      '/404',
    ];
    return hideSidebar.includes(v);
  };

  return (
    <>
      {
        isDesktop && (
          <div className="grid grid-flow-row">
            <Header />
            {
              !hideSidebar(pathname)
                ? <WithSideBar>
                  {children}
                </WithSideBar>
                : <div className='grid grid-flow-col'>
                  <main className="px-20 pt-10">
                    {children}
                  </main>
                </div>
            }
            {/* <footer className='pb-10' /> */}
          </div>
        )
      }
      {
        isMobile && !isDesktop && (
          <div className='grid grid-flow-row'>
            <main>
              {children}
            </main>
          </div>
        )
      }
    </>
  );
};

const WithSideBar: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='grid grid-flow-col auto-cols-sidebar w-dvw'>
      <Sidebar />
      <main className='w-[calc(100dvw-236px)] mx-[auto]'>
        {children}
      </main>
    </div>
  );
};

export default Responsive;
