import React from 'react';

import { TextButton } from '../ui/button/text-button';
import { Modal, ModalTrigger } from '../ui/modal/modal';
import { FollowKeywords, type FollowKeywordsProps } from './follow-keywords';

interface FollowKeywordsDialogProps extends FollowKeywordsProps {}
export const FollowKeywordsDialog: React.FC<FollowKeywordsDialogProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal defaultOpen={false} onOpenChange={setOpen} open={open}>
      <ModalTrigger asChild>
        <TextButton size='medium' variant={'primary'}>
          관심 키워드 변경하기
        </TextButton>
      </ModalTrigger>
      {open && <FollowKeywords {...props} onNext={(step, value) => {
        void props.onNext(step, value);
        void setOpen(false);
      }} />}
    </Modal>
  );
};